import getSymbolFromCurrency from 'currency-symbol-map';
import moment from 'moment';
import React from 'react';
import { Button, Card, Table } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import ReactPaginate from "react-paginate";
import { Link } from 'react-router-dom';
import formattedNumber from '../CommonComponents/FormatNumberUS.js';

const OrdersTable = ({ items = [], orderPagination, handlePageClick }) => {
  const { t } = useTranslation();

  const headList = [
    {
      title: 'Order ID',
      className: '',
      sort: '',
    },
    {
      title: 'Created Date',
      className: '',
      sort: '',
    },
    {
      title: 'Customer',
      className: '',
      sort: '',
    },
    {
      title: 'Price',
      className: '',
      sort: '',
    },
    {
      title: 'Tiktok Expense',
      className: '',
      sort: '',
    },
    {
      title: 'COGs',
      className: '',
      sort: '',
    },
    {
      title: 'Tax',
      className: '',
      sort: '',
    },
    {
      title: 'Net Profit',
      className: '',
      sort: '',
    },
    {
      title: 'Status',
      className: '',
      sort: '',
    },
    {
      title: 'Action',
      className: '',
      sort: '',
    },
  ];

  return (
    <Card>
      <Card.Body>
        <Table responsive className="w-100">
          <table id="example" className="w-100">
            <thead>
              <tr role="row">
                {headList.map((v, i) => (<th key={i} className={v.className}>{v.title}</th>))}
              </tr>
            </thead>
            <tbody>
              {items.map((item, index) => (
                <tr key={index}>
                  <td className="">
                    {item?.id}
                  </td>
                  <td className="">
                    {item?.create_time ? moment(item?.create_time).format("DD-MM-YYYY") : '-'}
                  </td>
                  <td className="">
                    {item?.recipient_address?.name}
                  </td>
                  <td className="">
                    {getSymbolFromCurrency(item?.payment?.currency)} {formattedNumber(parseFloat(item?.payment?.total_amount).toFixed(2))}
                  </td>
                  <td className="">
                    {getSymbolFromCurrency(item?.payment?.currency)} {formattedNumber(parseFloat(item?.total_charges).toFixed(2))}
                  </td>
                  <td className="">
                    {getSymbolFromCurrency(item?.payment?.currency)} {formattedNumber(parseFloat(item?.total_cost).toFixed(2))}
                  </td>
                  <td className="">
                    {getSymbolFromCurrency(item?.payment?.currency)} {formattedNumber(parseFloat(item?.total_tax).toFixed(2))}
                  </td>
                  <td className="">
                    {getSymbolFromCurrency(item?.payment?.currency)} {formattedNumber(parseFloat(item?.profit_loss).toFixed(2))}
                  </td>
                  <td className="">
                    {item?.status}
                  </td>
                  <td className="">
                    <Link to={`/tiktok-store/orders/${item?._id}/manage-order`} target="_blank">
                      <Button variant="outline-primary" className="btn-xs">
                        <i className="fa fa-lg fa-eye" aria-hidden="true"></i>
                      </Button>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </Table >
        {!items || items.length == 0 && <h5 className='d-flex justify-content-center'>{t('common.no_data_found')}</h5>}

        <div className="d-flex justify-content-between align-items-center mt-3">
          <div className="dataTables_info">
            {orderPagination.recordsCount == 0 ? "" :
              `Showing ${(((orderPagination.page - 1) * orderPagination.limit) + 1)} to ${Math.min(orderPagination.page * orderPagination.limit, orderPagination.recordsCount)} of ${orderPagination.recordsCount} entries`}
          </div>
          <div className="dataTables_paginate paging_simple_numbers">
            <ReactPaginate
              nextLabel="Next >"
              onPageChange={handlePageClick}
              pageRangeDisplayed={2}
              marginPagesDisplayed={1}
              pageCount={orderPagination.pageCount}
              forcePage={orderPagination.page - 1}
              previousLabel="< Previous"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName="pagination"
              activeClassName="active"
              renderOnZeroPageCount={null}
            />
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default OrdersTable;