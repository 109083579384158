import React, { useContext, useState } from "react";
import { Button, } from "react-bootstrap";
import { motion } from "framer-motion";
import { switchPlanType } from "../../../config";
import { GeneralContext } from "../../../GeneralLayout";
import { Context } from "../../../MasterLayout";

export const TickMarkSvg = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1_2)">
                <path d="M23.625 12C23.625 18.4219 18.4219 23.625 12 23.625C5.57812 23.625 0.375 18.4219 0.375 12C0.375 5.57812 5.57812 0.375 12 0.375C18.4219 0.375 23.625 5.57812 23.625 12ZM10.6547 18.1547L19.2797 9.52969C19.5703 9.23906 19.5703 8.76094 19.2797 8.47031L18.2203 7.41094C17.9297 7.12031 17.4516 7.12031 17.1609 7.41094L10.125 14.4422L6.83906 11.1562C6.54844 10.8656 6.07031 10.8656 5.77969 11.1562L4.72031 12.2156C4.42969 12.5063 4.42969 12.9844 4.72031 13.275L9.59531 18.15C9.88594 18.4406 10.3641 18.4406 10.6547 18.15V18.1547Z" fill="#1BE700" />
            </g>
            <defs>
                <clipPath id="clip0_1_2">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export const CrossMarkSvg = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1_5)">
                <path d="M12 0.375C5.57812 0.375 0.375 5.57812 0.375 12C0.375 18.4219 5.57812 23.625 12 23.625C18.4219 23.625 23.625 18.4219 23.625 12C23.625 5.57812 18.4219 0.375 12 0.375ZM17.7 15.0516C17.9203 15.2719 17.9203 15.6281 17.7 15.8484L15.8438 17.7C15.6234 17.9203 15.2672 17.9203 15.0469 17.7L12 14.625L8.94844 17.7C8.72812 17.9203 8.37187 17.9203 8.15156 17.7L6.3 15.8438C6.07969 15.6234 6.07969 15.2672 6.3 15.0469L9.375 12L6.3 8.94844C6.07969 8.72812 6.07969 8.37187 6.3 8.15156L8.15625 6.29531C8.37656 6.075 8.73281 6.075 8.95312 6.29531L12 9.375L15.0516 6.3C15.2719 6.07969 15.6281 6.07969 15.8484 6.3L17.7047 8.15625C17.925 8.37656 17.925 8.73281 17.7047 8.95312L14.625 12L17.7 15.0516Z" fill="#FF0202" />
            </g>
            <defs>
                <clipPath id="clip0_1_5">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

const PlanTable = ({ func, text, showFree = false }) => {
    const isUserLoggedIn = localStorage.getItem('user') && localStorage.getItem('token') ? true : false;
    const currentContext = localStorage.getItem('user') && localStorage.getItem('token') ? Context : GeneralContext;
    const { state, dispatch } = useContext(currentContext);

    const plans = {
        monthly: {
            free: {
                slug: 'free_plan',
                name: "Free Plan",
                price: 0,
            },
            basic: {
                slug: process.env.REACT_APP_BASIC_PLAN,
                name: process.env.REACT_APP_SUBSCRIPTION_PLAN_BASIC_NAME,
                price: process.env.REACT_APP_SUBSCRIPTION_PLAN_BASIC_PRICE,
            },
            growth: {
                slug: process.env.REACT_APP_GROWTH_PLAN,
                name: process.env.REACT_APP_SUBSCRIPTION_PLAN_GROWTH_NAME,
                price: process.env.REACT_APP_SUBSCRIPTION_PLAN_GROWTH_PRICE,
            },
            pro: {
                slug: process.env.REACT_APP_PRO_PLAN,
                name: process.env.REACT_APP_SUBSCRIPTION_PLAN_PRO_NAME,
                price: process.env.REACT_APP_SUBSCRIPTION_PLAN_PRO_PRICE,
            },
        },
        annual: {
            free: {
                slug: 'free_plan',
                name: "Free Annual",
                price: 0,
            },
            basic: {
                slug: process.env.REACT_APP_ANNUAL_BASIC_PLAN,
                name: process.env.REACT_APP_SUBSCRIPTION_PLAN_ANNUAL_BASIC_NAME,
                price: process.env.REACT_APP_SUBSCRIPTION_PLAN_ANNUAL_BASIC_PRICE,
            },
            growth: {
                slug: process.env.REACT_APP_ANNUAL_GROWTH_PLAN,
                name: process.env.REACT_APP_SUBSCRIPTION_PLAN_ANNUAL_GROWTH_NAME,
                price: process.env.REACT_APP_SUBSCRIPTION_PLAN_ANNUAL_GROWTH_PRICE,
            },
            pro: {
                slug: process.env.REACT_APP_ANNUAL_PRO_PLAN,
                name: process.env.REACT_APP_SUBSCRIPTION_PLAN_ANNUAL_PRO_NAME,
                price: process.env.REACT_APP_SUBSCRIPTION_PLAN_ANNUAL_PRO_PRICE,
            },
        },
    };

    var oddEven = 1;
    const planDetails = [
        {
            oddEven: oddEven,
            name: "",
            sub_name: "Pricing",
            free: `$${plans[state.selectedPlanType.value].free.price}`,
            basic: `$${plans[state.selectedPlanType.value].basic.price}`,
            growth: `$${plans[state.selectedPlanType.value].growth.price}`,
            pro: `$${plans[state.selectedPlanType.value].pro.price}`,
        },
        {
            oddEven: ++oddEven,
            name: "",
            sub_name: "Search",
            free: "5 Times/Day",
            basic: "100 Times/Day",
            growth: "300 Times/Day",
            pro: "Unlimited",
        },
        {
            oddEven: ++oddEven,
            name: "",
            sub_name: "View Detail Page",
            free: "5 Times/Day",
            basic: "100 Times/Day",
            growth: "300 Times/Day",
            pro: "Unlimited",
        },
        {
            oddEven: ++oddEven,
            name: "",
            sub_name: "Video Download",
            free: <CrossMarkSvg />,
            basic: "30 Times/Month",
            growth: "300 Times/Month",
            pro: "Unlimited",
        },
        {
            oddEven: ++oddEven,
            name: "",
            sub_name: "Script Download",
            free: <CrossMarkSvg />,
            basic: "30 Times/Month",
            growth: "300 Times/Month",
            pro: "Unlimited",
        },
        {
            oddEven: ++oddEven,
            name: "Products",
            rowspan: 3,
            sub_name: "Top Sales Products",
            free: "Top 10",
            basic: "Top 100",
            growth: "Top 300",
            pro: "Unlimited",
        },
        {
            oddEven: oddEven,
            name: "",
            firstColumn: false,
            sub_name: "Product Search",
            free: "Top 10",
            basic: "Top 100",
            growth: "Top 300",
            pro: "Unlimited",
        },
        {
            oddEven: oddEven,
            name: "",
            firstColumn: false,
            sub_name: "Product Info Export",
            free: <CrossMarkSvg />,
            basic: "10 Times/Month",
            growth: "15 Times/Month",
            pro: "20 Times/Month",
        },
        {
            oddEven: ++oddEven,
            name: "Influencers",
            rowspan: 3,
            sub_name: "Top Sales Influencers",
            free: "Top 10",
            basic: "Top 100",
            growth: "Top 300",
            pro: "Unlimited",
        },
        {
            oddEven: oddEven,
            name: "",
            firstColumn: false,
            sub_name: "Influencer Search",
            free: "Top 10",
            basic: "Top 100",
            growth: "Top 300",
            pro: "Unlimited",
        },
        {
            oddEven: oddEven,
            name: "",
            firstColumn: false,
            sub_name: "Influencer Info Export",
            free: <CrossMarkSvg />,
            basic: "10 Times/Month",
            growth: "15 Times/Month",
            pro: "20 Times/Month",
        },
        {
            oddEven: ++oddEven,
            name: "Videos",
            rowspan: 2,
            sub_name: "Top Sales Product Videos",
            free: "Top 10",
            basic: "Top 100",
            growth: "Top 300",
            pro: "Unlimited",
        },
        {
            oddEven: oddEven,
            name: "",
            firstColumn: false,
            sub_name: "Product Video Info Export",
            free: <CrossMarkSvg />,
            basic: "10 Times/Month",
            growth: "15 Times/Month",
            pro: "20 Times/Month",
        },
        {
            oddEven: ++oddEven,
            name: "Shops",
            rowspan: 3,
            sub_name: "Shop Ranking List",
            free: "Top 10",
            basic: "Top 100",
            growth: "Top 300",
            pro: "Unlimited",
        },
        {
            oddEven: oddEven,
            name: "",
            firstColumn: false,
            sub_name: "Shop Search",
            free: "Top 10",
            basic: "Top 100",
            growth: "Top 300",
            pro: "Unlimited",
        },
        {
            oddEven: oddEven,
            name: "",
            firstColumn: false,
            sub_name: "Shop Info Export",
            free: <CrossMarkSvg />,
            basic: "10 Times/Month",
            growth: "15 Times/Month",
            pro: "20 Times/Month",
        },
        {
            oddEven: ++oddEven,
            name: "Tools",
            rowspan: 3,
            sub_name: "Profit & Loss Dashboard",
            free: <CrossMarkSvg />,
            basic: <TickMarkSvg />,
            growth: <TickMarkSvg />,
            pro: <TickMarkSvg />,
        },
        {
            oddEven: oddEven,
            name: "",
            firstColumn: false,
            sub_name: "Profitability Calculator",
            free: <CrossMarkSvg />,
            basic: <TickMarkSvg />,
            growth: <TickMarkSvg />,
            pro: <TickMarkSvg />,
        },
        {
            oddEven: oddEven,
            name: "",
            firstColumn: false,
            sub_name: "Connect Stores",
            free: <CrossMarkSvg />,
            basic: 1,
            growth: 2,
            pro: 3,
        },
    ];

    return (
        <>
            <div className="d-flex justify-content-center justify-items-center">
                <div className="d-inline-flex align-items-center justify-content-center py-3 px-4 pri-btn-box">
                    {switchPlanType.map((item) => (
                        <Button
                            key={item.key}
                            onClick={() => dispatch({ type: 'switch_plan_type', response: item })}
                            className={`position-relative px-3 py-2 small fw-medium mx-1 ${state.selectedPlanType.value === item.value ? "text-white bg-primary" : "text-black bg-light"}`}
                            style={{ minWidth: "80px", transition: 'all 200ms ease-in-out' }}
                        >
                            {state.selectedPlanType.value === item.value && (
                                <motion.div
                                    layoutId="bubble"
                                    className="position-absolute top-0 start-0 end-0 bottom-0"
                                    transition={{ type: "spring", bounce: 0.2, duration: 0.6 }}
                                />
                            )}
                            <span className="d-flex align-items-center justify-content-center">
                                {item.key}
                            </span>
                            {item.value == 'annual' && <div className="custm-badge">2 Month Free</div>}
                        </Button>
                    ))}
                </div>
            </div>
            <div className="py-5 text-center">
                <table id="price_table" className="display w-100 dataTable table-striped">
                    <thead className="border-light">
                        <tr>
                            <th scope="col">
                                <strong>

                                </strong>
                            </th>
                            <th scope="col">
                                <strong>
                                    Features
                                </strong>
                            </th>
                            {showFree && <th scope="col">
                                <strong>
                                    {plans[state.selectedPlanType.value].free.name}
                                </strong>
                            </th>}
                            <th scope="col">
                                <strong>
                                    {plans[state.selectedPlanType.value].basic.name}
                                </strong>
                            </th>
                            <th scope="col">
                                <strong>
                                    {plans[state.selectedPlanType.value].growth.name}
                                </strong>
                            </th>
                            <th scope="col">
                                <strong>
                                    {plans[state.selectedPlanType.value].pro.name}
                                </strong>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th></th>
                            <th></th>
                            {showFree && <th>
                                <Button
                                    variant="outline-primary"
                                    className="my-3 px-4 outline-primary-one"
                                    onClick={() => func(plans[state.selectedPlanType.value]['free'])}>
                                    {text}
                                </Button>
                            </th>}
                            <th>
                                <Button
                                    variant="outline-primary"
                                    className="my-3 px-4 outline-primary-two"
                                    onClick={() => func(plans[state.selectedPlanType.value]['basic'])}>
                                    {text}
                                </Button>
                            </th>
                            <th>
                                <Button
                                    variant="outline-primary"
                                    className="my-3 px-4 outline-primary-three"
                                    onClick={() => func(plans[state.selectedPlanType.value]['growth'])}>
                                    {text}
                                </Button>
                            </th>
                            <th>
                                <Button
                                    variant="outline-primary"
                                    className="my-3 px-4 outline-primary-four"
                                    onClick={() => func(plans[state.selectedPlanType.value]['pro'])}>
                                    {text}
                                </Button>
                            </th>
                        </tr>
                        {planDetails.map((row, idx) => (
                            <>
                                {row?.name && <tr key={idx + "id"}>
                                    <th scope="row"></th>
                                    <th scope="row"></th>
                                    {showFree && <td></td>}
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>}
                                <tr key={idx} className={row.oddEven % 2 == 0 ? 'gray-cell' : 'white-cell' }>
                                    {row.firstColumn !== false &&
                                    <th scope="row" rowspan={row?.rowspan && row?.rowspan}>
                                        {row?.name}
                                    </th> }
                                    <th scope="row">{row?.sub_name}</th>
                                    {showFree && <td>{row?.free}</td>}
                                    <td>{row?.basic}</td>
                                    <td>{row?.growth}</td>
                                    <td>{row?.pro}</td>
                                </tr>
                            </>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    )
};

export default PlanTable;