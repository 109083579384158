import React, { Component } from "react";

/// Link
import { Link } from "react-router-dom";

/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
import { withTranslation } from 'react-i18next';



/// Menu
import MetisMenu from "metismenujs";

class MM extends Component {
   componentDidMount() {
      this.$el = this.el;
      this.mm = new MetisMenu(this.$el);
   }
   componentWillUnmount() {
      //this.mm("dispose");
      this.mm.dispose();
   }
   render() {
      return (
         <div className="mm-wrapper">
            <ul className="metismenu" ref={(el) => (this.el = el)}>
               {this.props.children}
            </ul>
         </div>
      );
   }
}

class SideBar extends Component {
   // eslint-disable-next-line no-useless-constructor
   constructor(props) {
      super(props);
   }
   /// model box
   state = {
      // initial state
      show: false,
   }

   handleClose = () => {
      this.setState({ show: false });
   };
   handleShow = () => {
      this.setState({ show: true });
   };

   /// Open menu
   componentDidMount() {
      // sidebar open/close
      var btn = document.querySelector(".nav-control");
      var aaa = document.querySelector("#main-wrapper");
      function toggleFunc() {
         return aaa.classList.toggle("menu-toggle");
      }
      btn.addEventListener("click", toggleFunc);
   }
   render() {
      /// Path
      let path = window.location.pathname;
      path = path.split("/");
      path = path[path.length - 1];
      const user_type = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).user_type : '';

      let deshBoard = [
         "dashboard",
      ];
      let products = [
         "top-sales",
         "products-videos",
         "products-search",
         "price-calculator",
      ];
      let influencers = [
         "top-sales-influencers",
         "influencers-search",
         "influencers-review-videos",
         "content-creation",
         "discoveryInfluencers",
         "video-creators",
         "breakout",
         "influencers-details",
      ];
      let shops = [
         "shop-ranking-list",
         "shop-search",
      ];
      let tiktokstore = [
         "store-manager",
         "profit-dashboard",
         "orders",
         "store-listing",
         "product-master-list",
         "reports",
         "expense-manager",
      ];
      let downloadCenter = [
         "exports",
      ];
      let printifyp = [
         "printify",
      ];
      let supports = [
         "tutorials",
         "contact-support",
      ];
      let admintool = [
         "user-management",
         "coupon",
         "create",
         "register-finance-report",
         "influancer-video",
         "questions",
         "answers",
         "event-log",
      ];
      const { t } = this.props;

      return (
         <div className="dlabnav">
            <PerfectScrollbar className="dlabnav-scroll">
               <MM className="metismenu" id="menu">
                  <li className={`${deshBoard.includes(path) ? "mm-active" : ""}`}>
                     <Link to="/dashboard" className="ai-icon">
                        <i className="flaticon-381-networking"></i>
                        <span className="nav-text">{t('sideBar.dashboard')}</span>
                     </Link>
                  </li>

                  <li className={`${products.includes(path) ? "mm-active" : ""}`}>
                     <Link className="has-arrow ai-icon" to="#">
                        <i className="flaticon-381-briefcase"></i>
                        <span className="nav-text">{t('sideBar.products_research')}</span>
                     </Link>
                     <ul>
                        <li>
                           <Link
                              className={`${path === "top-sales" ? "mm-active" : ""}`}
                              to="/products/top-sales"
                              onClick={() => this.props.onClick3()}
                           >
                              {t('sideBar.top_sales')}
                           </Link>
                        </li>
                        <li>
                           <Link
                              className={`${path === "products-search" ? "mm-active" : ""}`}
                              to="/products/products-search"
                              onClick={() => this.props.onClick3()}
                           >
                              {t('sideBar.products')}
                           </Link>
                        </li>
                        <li>
                           <Link
                              className={`${path === "products-videos" ? "mm-active" : ""}`}
                              to="/products/products-videos"
                              onClick={() => this.props.onClick3()}
                           >
                              {t('sideBar.product_video')}
                           </Link>
                        </li>
                        <li>
                           <Link
                              className={`${path === 'price-calculator' ? "mm-active" : ""}`}
                              to="/price-calculator"
                              onClick={() => this.props.onClick3()}
                           >
                              {t('sideBar.profitability_calculator')}
                           </Link>
                        </li>
                     </ul>
                  </li>

                  {/* INFLUENCERS */}
                  <li className={`${influencers.includes(path) ? "mm-active" : ""}`}>
                     <Link className="has-arrow ai-icon" to="#">
                        <i className="flaticon-381-user-1"></i>
                        <span className="nav-text">{t('sideBar.influencers')}</span>
                     </Link>
                     <ul>
                        <li>
                           <Link
                              className={`${path === "top-sales-influencers" ? "mm-active" : ""}`}
                              to="/influencers/top-sales-influencers"
                              onClick={() => this.props.onClick3()}
                           >
                              {t('sideBar.top_sales_influencers')}
                           </Link>
                        </li>
                        <li>
                           <Link
                              className={`${path === "influencers-search" ? "mm-active" : ""}`}
                              to="/influencers/influencers-search"
                              onClick={() => this.props.onClick3()}
                           >
                              {t('sideBar.influencers_search')}
                           </Link>
                        </li>
                        <li>
                           <Link
                              className={`${path === "influencers-review-videos" ? "mm-active" : ""}`}
                              to="/influencers-review-videos"
                              onClick={() => this.props.onClick3()}
                           >
                              {t('sideBar.content_creation')}
                           </Link>
                        </li>
                     </ul>
                  </li>

                  {/* SHOPS CENTER */}
                  <li className={`${shops.includes(path) ? "mm-active" : ""}`}>
                     <Link className="has-arrow ai-icon" to="#">
                        <i className="flaticon-381-home-1"></i>
                        <span className="nav-text">{t('sideBar.shops')}</span>
                     </Link>
                     <ul>
                        <li>
                           <Link
                              className={`${path === "shop-ranking-list" ? "mm-active" : ""}`}
                              to="/shops/shop-ranking-list"
                              onClick={() => this.props.onClick3()}
                           >
                              {t('sideBar.shop_ranking_list')}
                           </Link>
                        </li>
                        <li>
                           <Link
                              className={`${path === "shop-search" ? "mm-active" : ""}`}
                              to="/shops/shop-search"
                              onClick={() => this.props.onClick3()}
                           >
                              {t('sideBar.shop_search')}
                           </Link>
                        </li>
                     </ul>
                  </li>
                  {/* CREATOR CENTER */}
                  {/* <li className={`${creator.includes(path) ? "mm-active" : ""}`}>
                     <Link className="has-arrow ai-icon" to="#">
                        <i className="flaticon-381-help-1"></i>
                        <span className="nav-text">{t('sideBar.creator_center')}</span>
                     </Link>
                     <ul>
                        <li>
                           <Link                           
                              className={`${path === "songs/ranking" ? "mm-active" : ""}`}
                              to="/songs/ranking"
                              onClick={() => this.props.onClick3()}
                           >
                              {t('sideBar.songs_ranking')}
                           </Link>
                        </li>                        
                     </ul>
                  </li> */}

                  <li className={`${tiktokstore.includes(path) ? "mm-active" : ""}`}>
                     <Link className="has-arrow ai-icon" to="#">
                        <i className="flaticon-381-folder"></i>
                        <span className="nav-text"> {t('sideBar.my_tiktok_analytics')}</span>
                     </Link>
                     <ul>
                        <li>
                           <Link
                              className={`${path === "profit-dashboard" ? "mm-active" : ""}`}
                              to="/tiktok-store/profit-dashboard"
                              onClick={() => this.props.onClick3()}
                           >
                              {t('sideBar.profit_dashboard')}
                           </Link>
                        </li>
                        <li>
                           <Link
                              className={`${path === "orders" ? "mm-active" : ""}`}
                              to="/tiktok-store/orders"
                              // to="/tiktok-store/store-manager"
                              onClick={() => this.props.onClick3()}
                           >
                              {t('sideBar.manage_orders')}
                           </Link>
                        </li>
                        <li>
                           <Link
                              className={`${path === "store-listing" ? "mm-active" : ""}`}
                              to="/tiktok-store/store-listing"
                              // to="/tiktok-store/store-manager"
                              onClick={() => this.props.onClick3()}
                           >
                              {t('sideBar.store_manager')}
                           </Link>
                        </li>
                        <li>
                           <Link
                              className={`${path === "product-master-list" ? "mm-active" : ""}`}
                              to="/tiktok-store/product-master-list"
                              onClick={() => this.props.onClick3()}
                           >
                              {t('sideBar.product_master_list')}
                           </Link>
                        </li>
                        <li>
                           <Link
                              className={`${path === "expense-manager" ? "mm-active" : ""}`}
                              to="/tiktok-store/expense-manager"
                              onClick={() => this.props.onClick3()}
                           >
                              {t('sideBar.expense_manager')}
                           </Link>
                        </li>
                        <li>
                           <Link
                              className={`${path === "reports" ? "mm-active" : ""}`}
                              to="/tiktok-store/reports"
                              onClick={() => this.props.onClick3()}
                           >
                              {t('sideBar.reports')}
                           </Link>
                        </li>
                     </ul>
                  </li>
                  <li className={`${downloadCenter.includes(path) ? "mm-active" : ""}`}>
                     <Link className="has-arrow ai-icon" to="#">
                        <i className="flaticon-381-download"></i>
                        <span className="nav-text">{t('sideBar.download_center')}</span>
                     </Link>
                     <ul>
                        <li>
                           <Link
                              className={`${path === "exports" ? "mm-active" : ""}`}
                              to="/exports"
                              onClick={() => this.props.onClick3()}
                           >
                              {t('sideBar.exports')}
                           </Link>
                        </li>
                     </ul>
                  </li>
                  <li className={`${printifyp.includes(path) ? "mm-active" : ""}`}>
                     <Link to="/products/printify" className="ai-icon">
                        <i className="flaticon-381-print-1"></i>
                        <span className="nav-text">{t('sideBar.printify_products')}</span>
                     </Link>
                  </li>
                  <li className={`${supports.includes(path) ? "mm-active" : ""}`}>
                     <Link className="has-arrow ai-icon" to="#">
                        <i className="flaticon-381-help-1"></i>
                        <span className="nav-text">{t('sideBar.need_help')}</span>
                     </Link>
                     <ul>
                        <li>
                           <Link
                              className={`${path === "tutorials" ? "mm-active" : ""}`}
                              to="/tutorials"
                              onClick={() => this.props.onClick3()}
                           >
                              {t('sideBar.tutorials')}
                           </Link>
                        </li>
                        <li>
                           <Link
                              className={`${path === "contact-support" ? "mm-active" : ""}`}
                              to="/contact-support"
                              onClick={() => this.props.onClick3()}
                           >
                              {t('sideBar.contact_support')}
                           </Link>
                        </li>
                     </ul>
                  </li>

                  {user_type == 'admin' && <li className={`${admintool.includes(path) ? "mm-active" : ""}`}>
                     <Link className="has-arrow ai-icon" to="#">
                        <i className="flaticon-381-controls-1"></i>
                        <span className="nav-text">Admin Tools</span>
                     </Link>
                     <ul>
                        <li>
                           <Link
                              className={`${path === "user-management" ? "mm-active" : ""}`}
                              to="/user-management"
                              onClick={() => this.props.onClick3()}
                           >
                              {t('sideBar.user_management')}
                           </Link>
                        </li>
                        <li>
                           <Link
                              className={`${path === "coupon" || path === "create" || path === "edit" ? "mm-active" : ""}`}
                              to="/coupon"
                              onClick={() => this.props.onClick3()}
                           >
                              {t('sideBar.coupons')}
                           </Link>
                        </li>
                        <li>
                           <Link
                              className={`${path === "register-finance-report" ? "mm-active" : ""}`}
                              to="/register-finance-report"
                              onClick={() => this.props.onClick3()}
                           >
                              {t('sideBar.register_finance_report')}
                           </Link>
                        </li>
                        <li>
                           <Link
                              className={`${path === "influancer-video" ? "mm-active" : ""}`}
                              to="/influancer-video"
                              onClick={() => this.props.onClick3()}
                           >
                              {t('sideBar.influancer_videos')}
                           </Link>
                        </li>
                        <li>
                           <Link
                              className={`${path === "questions" ? "mm-active" : ""}`}
                              to="/oneoffuser/questions"
                              onClick={() => this.props.onClick3()}
                           >
                              {t('sideBar.oneoff_questions')}
                           </Link>
                        </li>
                        <li>
                           <Link
                              className={`${path === "answers" ? "mm-active" : ""}`}
                              to="/oneoffuser/answers"
                              onClick={() => this.props.onClick3()}
                           >
                              {t('sideBar.oneoff_answers')}
                           </Link>
                        </li>
                        <li>
                           <Link
                              className={`${path === "event-log" ? "mm-active" : ""}`}
                              to="/event-log"
                              onClick={() => this.props.onClick3()}
                           >
                              {t('sideBar.event_log')}
                           </Link>
                        </li>
                     </ul>
                  </li>}
               </MM>

               <div className="copyright">
                  <p>
                     <strong>Viralytic</strong> {t('sideBar.all_rights_reserved')}
                  </p>
               </div>
            </PerfectScrollbar>
         </div>
      );
   }
}

export default withTranslation()(SideBar);
