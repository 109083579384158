import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, Col, Form, Modal, Row, Table } from "react-bootstrap";
import DateRangePicker from "react-bootstrap-daterangepicker";
import DatePicker from "react-datepicker";
import { useTranslation } from 'react-i18next';
import ReactPaginate from 'react-paginate';
import Select from "react-select";
import { Context } from '../../../MasterLayout.js';
import Axios from '../../plugins/axios.js';
import { isEmpty } from '../CommonComponents/isEmpty.js';
import ProfitLanding from "./ProfitLanding";
import StoreFetch from "./StoreFetch";

const headList = [
  {
    title: 'Store Name',
    className: '',
    sort: '',
  },
  {
    title: 'Category',
    className: '',
    sort: '',
  },
  {
    title: 'Amount',
    className: '',
    sort: '',
  },
  {
    title: 'Description',
    className: '',
    sort: '',
  },
  {
    title: 'Expense Date',
    className: '',
    sort: '',
  },
  {
    title: 'Action',
    className: '',
    sort: '',
  },
];

const ExpenseForm = ({ formData, setFormData, errors, setErrors, stores }) => {
  const { dispatch } = useContext(Context);
  const [items, setItems] = useState([]);


  const handleChange = (e) => {
    setFormData((prev) => ({ ...prev, [e?.target?.name]: e?.target?.value }));
    setErrors((prev) => ({ ...prev, [e?.target?.name]: '' }));
  };

  async function getCategory() {
    try {
      dispatch({ type: 'loader_show', response: true });
      let response = await Axios.get(`/api/value-lookup?value=EXPENSE_CATEGORY`).catch((e) => console.error('Axios-Catch: ' + e.message));

      dispatch({ type: 'subscription_expired', response: response?.data?.statusCode });

      if (response && response.status === 200 && response.data) {
        setItems(response.data)
      };
    } catch (error) {
      console.error('Error-Catch: ' + error.message);
    };
    dispatch({ type: 'loader_hide', response: true });
  }

  useEffect(() => {
    getCategory();
  }, [])


  return (
    <div>
      <div className="input-group mb-3">
        <div className="col-sm-6">
          <Select
            placeholder="Select Store"
            closeMenuOnSelect={true}
            options={stores}
            onChange={(e) => {
              setFormData((prev) => ({ ...prev, store_id: e._id }))
              setErrors((prev) => ({ ...prev, store_id: '' }))
            }}
          />
          {errors?.store_id ? (
            <p className="text-danger">{errors?.store_id}</p>
          ) : null}
        </div>
        <div className="col-sm-6">
          <Select
            placeholder="Select Category"
            closeMenuOnSelect={true}
            options={items}
            onChange={(e) => {
              setFormData((prev) => ({ ...prev, category: e.value, category_id: e.value }))
              setErrors((prev) => ({ ...prev, category: '' }))
            }}
          />
          {(formData?.category_id !== 'Other' && errors?.category) ? (
            <p className="text-danger">{errors?.category}</p>
          ) : null}
        </div>
      </div>

      {formData?.category_id === 'Other' && <div className="input-group mb-3">
        <div className="col-sm-6"></div>
        <div className="col-sm-6">
          <input
            type="text"
            className={`form-control form-control-sm `}
            placeholder="Enter Category"
            name="category"
            value={formData?.category}
            onChange={handleChange}
            required />
          {errors?.category ? (
            <p className="text-danger">{errors?.category}</p>
          ) : null}
        </div>
      </div >}

      <div className="input-group mb-3">
        <div className="col-sm-6">
          <input
            type="text"
            className={`form-control form-control-sm `}
            placeholder={"Description"}
            name="description"
            value={formData?.description}
            onChange={handleChange}
            required />
          {errors?.description ? (
            <p className="text-danger">{errors?.description}</p>
          ) : null}
        </div>
        <div className="col-sm-6">
          <input
            type="number"
            className={`form-control form-control-sm `}
            name="amount"
            placeholder={"Amount"}
            value={formData?.amount}
            onChange={handleChange}
            onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}
            required
          />
          {errors?.amount ? (
            <p className="text-danger">{errors?.amount}</p>
          ) : null}
        </div>
      </div>
      <div className="input-group mb-3">
        <div className="col-sm-6">
          <DatePicker
            showIcon
            isClearable
            closeOnScroll={true}
            maxDate={new Date()}
            placeholderText={"Expense Date"}
            className="form-control form-control-sm"
            name="expense_date"
            selected={formData?.expense_date ? new Date(formData?.expense_date) : formData?.expense_date}
            onChange={(date) => {
              setFormData((prev) => ({ ...prev, expense_date: date }))
              setErrors((prev) => ({ ...prev, expense_date: '' }))
            }}
          />
          {errors?.expense_date ? (
            <p className="text-danger">{errors?.expense_date}</p>
          ) : null}
        </div>
      </div>
    </div >
  );
};

const ExpenseManager = () => {
  const limitArray = [10, 25, 50, 100];
  const { t } = useTranslation();
  const { dispatch } = useContext(Context);
  const [items, setItems] = useState([]);
  const [errors, setErrors] = useState({});
  const [loadedOnce, setLoadedOnce] = useState(false);
  const [productCount, setProductCount] = useState(0);
  const [currentStore, setCurrentStore] = useState("");
  const [stores, setStores] = useState([]);
  const [formData, setFormData] = useState({
    store_id: "",
    category: "",
    expense_date: "",
    amount: "",
    description: "",
  });
  const [modalShow, setModalShow] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [orderPagination, setOrderPagination] = useState({
    limit: 10,
    page: 1,
    recordsCount: 0,
    pageCount: 0
  });
  const [bestSellerDateRange, setBestSellerDateRange] = useState({ start_date: new Date(new Date().setDate(new Date().getDate() - 30)), end_date: new Date() });


  const validateFields = (label, value) => {
    let error = "";

    switch (label) {
      case "store_id":
        if (!value.trim()) error = "Please select store";
        break;
      case "description":
        if (!value.trim()) error = "Please enter description";
        break;
      case "category":
        if (!value.trim()) error = "Please enter category";
        break;
      case "amount":
        if (!value) error = "Please enter amount";
        break;
      case "expense_date":
        if (!value) error = "Please select expense date";
        break;
      default:
        break;
    }
    setErrors((prevErrors) => ({ ...prevErrors, [label]: error }));
    return { ...errors, [label]: error };
  };

  useEffect(() => {
    if (isEmpty(stores)) {
      getStores();
    } else {
      getExpenses();
    };
  }, [orderPagination.page, orderPagination.limit, bestSellerDateRange?.start_date, bestSellerDateRange?.end_date, currentStore])

  const getExpenses = async () => {
    try {
      dispatch({ type: 'loader_show', response: true });
      let response = await Axios.get(`/api/expense?store_id=${currentStore._id || ""}&page=${orderPagination.page}&limit=${orderPagination.limit}&start_date=${bestSellerDateRange?.start_date}&end_date=${bestSellerDateRange?.end_date}`).catch((e) => console.error('Axios-Catch: ' + e.message));

      dispatch({ type: 'subscription_expired', response: response.data.statusCode });

      if (response && response.status === 200 && response.data?.data) {
        setOrderPagination((pre) => ({
          ...pre,
          pageCount: response.data.data.totalPages,
          recordsCount: response.data.data.noOfRecords,
        }))
        setItems(response.data.data.records)
      };
    } catch (error) {
      console.error('Error-Catch: ' + error.message);
    };
    dispatch({ type: 'loader_hide', response: true });
  };

  const handleApply = (event, picker) => {
    picker.element.val(picker.startDate.format('MM/DD/YYYY') + ' - ' + picker.endDate.format('MM/DD/YYYY'));
    setBestSellerDateRange({ start_date: picker.startDate.format('YYYY-MM-DD'), end_date: picker.endDate.format('YYYY-MM-DD') })
  };

  const handlePageClick = (event) => {
    setOrderPagination((pre) => ({
      ...pre,
      page: event.selected + 1
    }));
  };

  const handleSave = async () => {
    try {
      let newErrors = {}, updatedData = { ...formData }, requiredFields = [
        "store_id",
        "description",
        "category",
        "amount",
        "expense_date",
      ];

      requiredFields.forEach((field) => {
        const err = validateFields(field, updatedData[field]);
        if (err[field]) {
          newErrors[field] = err[field];
        }
      });

      if (Object.keys(newErrors).length > 0) {
        setErrors(newErrors);
        return;
      } else {

        dispatch({ type: 'loader_show', response: true });
        const res = updatedData?._id
          ? await Axios.patch(`/api/expense/${updatedData?._id}`, updatedData)
          : await Axios.post(`/api/expense`, updatedData);

        if (res.status === 200) {
          dispatch({ type: 'loader_hide', response: true });
          getExpenses();
          setModalShow(false)
        };
      }
    } catch (error) {
      dispatch({ type: 'loader_hide', response: true });
    }
  };

  async function handleDelete() {
    try {
      dispatch({ type: 'loader_show', response: true });
      const res = await Axios.delete(`/api/expense/${formData?._id}`);

      if (res.status === 200) {
        dispatch({ type: 'loader_hide', response: true });
        getExpenses();
        setDeleteModal(false)
      };
    } catch (error) {

    }
    dispatch({ type: 'loader_hide', response: true });
  }

  async function resetStates() {
    setFormData({
      store_id: "",
      category: "",
      category_id: "",
      expense_date: "",
      amount: "",
      description: "",
    })
    setErrors({})
  }

  const getStores = async () => {
    try {
      dispatch({ type: 'loader_show', response: true });
      let response = await Axios.get('/api/dashboard/stores');
      dispatch({ type: 'subscription_expired', response: response.data.statusCode });

      if (response && response.status === 200) {
        setLoadedOnce(true);
        response.data.data.stores.map((v) => {
          v.value = v._id;
          v.label = v.name;
          return v;
        });
        setStores(response.data.data.stores);
        let storeIndex = 0;
        if (response.data?.data?.default_store) {
          storeIndex = response.data.data.stores.findIndex(n => n._id == response.data.data.default_store)
        };
        setCurrentStore(response.data.data.stores[storeIndex])
        setProductCount(response.data?.data?.productCount);
      };
    } catch (error) {
      console.error('Error-Catch: ' + error.message);
    };
    dispatch({ type: 'loader_hide', response: true });
  };

  return (
    <>
      {stores.length > 0 &&
        <>
          <div className="revenue-section">
            <Row className="align-items-center mb-3">
              <Col md="9" className="text-center text-sm-left">
              </Col>
              <Col md="3" className="">
                <div className="dropdown product-rating-menu mr-2">
                  <Select
                    closeMenuOnSelect={true}
                    defaultValue={currentStore}
                    options={stores}
                    onChange={(e) => setCurrentStore(e)}
                  />
                </div>
              </Col>
            </Row>
          </div>
          {productCount != 0 &&
            <div className="bestsellers-section">
              <Row className="align-items-center mb-3">
                <Col md="6" className="text-center text-sm-left">
                  <h4 className="fw-bolder">Expenses</h4>
                </Col>
                <Col md="6" className="">
                  <div className="d-flex align-items-center justify-content-end flex-column flex-sm-row">
                    <div className="dropdown product-rating-menu mr-1">
                      <Form.Select className="form-control form-control-sm" defaultValue={orderPagination.limit}
                        onChange={(e) => { setOrderPagination((prev) => ({ ...prev, limit: e.target.value })); }}
                      >
                        {limitArray.map((v, i) => { return <option key={i} value={v}>{v}</option> })}
                      </Form.Select>
                    </div>
                    <div className="dropdown product-rating-menu mr-1">
                      <DateRangePicker
                        onApply={handleApply} initialSettings={{
                          autoUpdateInput: false,
                          locale: { cancelLabel: 'Clear', },
                          ranges: {
                            'Today': [moment().toDate(), moment().toDate()],
                            'Yesterday': [moment().subtract(1, 'days').toDate(), moment().subtract(1, 'days').toDate()],
                            'Last 7 Days': [moment().subtract(6, 'days').toDate(), moment().toDate()],
                            'Last 30 Days': [moment().subtract(29, 'days').toDate(), moment().toDate()],
                            'This Month': [moment().startOf('month').toDate(), moment().toDate()],
                            'Last Month': [moment().subtract(1, 'month').startOf('month').toDate(), moment().subtract(1, 'month').endOf('month').toDate()],
                            'Last 3 Month': [moment().subtract(3, 'month').startOf('month').toDate(), moment().subtract(1, 'month').endOf('month').toDate()],
                            'Last 6 Month': [moment().subtract(6, 'month').startOf('month').toDate(), moment().subtract(1, 'month').endOf('month').toDate()],
                          },
                        }}>
                        <input type="text" placeholder="Select Date" className="form-control w-auto form-control-sm input-daterange-timepicker pull-right" />
                      </DateRangePicker>
                    </div>
                    <div className="dropdown product-rating-menu mr-1">
                      <Button onClick={() => { resetStates(); setModalShow(true) }} className="w-auto btn-xs mr-2"> Add Expense</Button>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Card>
                    <Card.Body className="p-0">
                      <Table responsive className="w-100">
                        <table id="example" className="w-100">
                          <thead>
                            <tr role="row">
                              {headList.map((v, i) => (<th key={i} className={v.className}>{v.title}</th>))}
                            </tr>
                          </thead>
                          <tbody>
                            {items.map((item, index) => (
                              <tr key={index}>
                                <td className="">
                                  {item?.store?.name}
                                </td>
                                <td className="">
                                  {item?.category}
                                </td>
                                <td className="">
                                  {item?.amount}
                                </td>
                                <td className="">
                                  {item?.description}
                                </td>
                                <td className="">
                                  {item?.expense_date ? moment(item?.expense_date).format("DD-MM-YYYY") : '-'}
                                </td>
                                <td className="">
                                  <Button variant="outline-dark" className="px-2 py-1 mx-1 btn btn-outline-dark btn-xs" onClick={() => { setFormData(item); setModalShow(true) }}><i className="fa fa-lg fa-pencil"></i></Button>
                                  <Button variant="outline-dark" className="px-2 py-1 mx-1 btn btn-outline-dark btn-xs" onClick={() => { setFormData(item); setDeleteModal(true) }}><i className="fa fa-lg fa-trash"></i></Button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </Table >
                      {!items || items.length == 0 && <h5 className='d-flex justify-content-center'>{t('common.no_data_found')}</h5>}

                      <div className="d-flex justify-content-between align-items-center mt-3">
                        <div className="dataTables_info">
                          {orderPagination.recordsCount == 0 ? "" :
                            `Showing ${(((orderPagination.page - 1) * orderPagination.limit) + 1)} to ${Math.min(orderPagination.page * orderPagination.limit, orderPagination.recordsCount)} of ${orderPagination.recordsCount} entries`}
                        </div>
                        <div className="dataTables_paginate paging_simple_numbers">
                          <ReactPaginate
                            nextLabel="Next >"
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={2}
                            marginPagesDisplayed={1}
                            pageCount={orderPagination.pageCount}
                            forcePage={orderPagination.page - 1}
                            previousLabel="< Previous"
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakLabel="..."
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            containerClassName="pagination"
                            activeClassName="active"
                            renderOnZeroPageCount={null}
                          />
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              <Modal className="fade bd-example-modal-lg" show={modalShow} size="lg">
                <Modal.Header>
                  <Modal.Title>{formData?._id ? "Update Expense" : "Add Expense"}</Modal.Title>
                  <Button variant="danger" className="close" size="sm" onClick={() => { setModalShow(false); }}>
                    X
                  </Button>
                </Modal.Header>
                <Modal.Body>
                  <ExpenseForm
                    formData={formData}
                    setFormData={setFormData}
                    errors={errors}
                    setErrors={setErrors}
                    stores={stores}
                  />
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="outline-danger" size="sm" onClick={() => { setModalShow(false) }}>
                    {t('user_management.cancel')}
                  </Button>
                  <Button variant="primary" type="button" size="sm" className="btn btn-primary" onClick={handleSave}>
                    {formData?._id ? "Update Expense" : "Add Expense"}
                  </Button>
                </Modal.Footer>
              </Modal>

              <Modal show={deleteModal} onHide={() => setDeleteModal(false)} >
                <Modal.Body className='p-0'>
                  <Card>
                    <Card.Body>
                      {t('common.are_you_sure_you_want_to_delete')} ?
                    </Card.Body>
                  </Card>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleDelete}>
                    {t('common.yes')}
                  </Button>
                  <Button variant="primary" onClick={() => setDeleteModal(false)}>
                    {t('common.no')}
                  </Button>
                </Modal.Footer>
              </Modal>

            </div>
          }
          {productCount == 0 && loadedOnce && <StoreFetch />}
        </>
      }
      {stores.length == 0 && loadedOnce && <ProfitLanding />}
    </>

  );
};

export default ExpenseManager;