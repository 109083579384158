import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import React from "react";
import { withTranslation } from 'react-i18next';

am4core.useTheme(am4themes_animated);

class UnitSoldChart extends React.Component {
  componentDidMount() {
    const { t } = this.props;
    var chart = am4core.create("unit_sold_chart_div", am4charts.XYChart);

    // Add percent sign to all numbers
    chart.numberFormatter.numberFormat = "#";

    // Add data
    chart.data = this.props.data.unit_sold;

    // Legend
    chart.legend = new am4charts.Legend();
    chart.legend.useDefaultMarker = true;
    var markerTemplate = chart.legend.markers.template;
    markerTemplate.width = 20;
    markerTemplate.height = 20;
    markerTemplate.stroke = am4core.color("#ccc");

    // Create axes
    var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "country";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 30;

    var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = t('dashboard.unit_sold');
    valueAxis.title.fontWeight = 800;
    valueAxis.numberFormatter = new am4core.NumberFormatter();
    valueAxis.numberFormatter.numberFormat = "#,###";
    // Create series
    var series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueY = this.props.data.current_month;
    series.name = this.props.data.current_month;
    series.dataFields.categoryX = "country";
    series.clustered = false;
    series.tooltipText = `${this.props.data.current_month} (${this.props.data.current_year}): [bold]{valueY}`;
    series.fill = am4core.color("#1c72fd");
    series.stroke = am4core.color("#1c72fd");

    var series2 = chart.series.push(new am4charts.ColumnSeries());
    series2.dataFields.valueY = this.props.data.last_month;
    series2.name = this.props.data.last_month;
    series2.dataFields.categoryX = "country";
    series2.clustered = false;
    series2.columns.template.width = am4core.percent(50);
    series2.tooltipText = `${this.props.data.last_month} (${this.props.data.last_year}): [bold]{valueY}`;
    series2.fill = am4core.color("#80b1fe");
    series2.stroke = am4core.color("#80b1fe");
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.lineX.disabled = true;
    chart.cursor.lineY.disabled = true;
    if (chart.logo) {
      chart.logo.disabled = true;
    }

    this.chart = chart;

  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }


  render() {
    return (
      <div id="unit_sold_chart_div" style={{ width: "100%", height: "400px" }}></div>
    );
  }
}


export default withTranslation()(UnitSoldChart);